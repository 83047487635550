import type { RouteLocationNormalized } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import { setupLayouts } from 'virtual:generated-layouts';
import auth from '@/middleware/auth';
import freeClass from '@/middleware/freeClass';
import { useUserStore } from '@/stores/user';
import { usePopStore } from '@/stores/pop';
import { useCurrentDay } from '@/composables/useCurrentDay';
import compareDates from '@/composables/useCompareDate';
import { computed } from 'vue';
import customHome from '@/middleware/customHome';
import { useAppStore } from '@/stores/app';
import { RoleType } from '@/models/interface/user';

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/IndexView.vue'),
    meta: {
      middleware: [auth, freeClass, customHome]
    }
  },
  /** 行事曆 */
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/CalendarView.vue'),
    meta: {
      middleware: [auth, freeClass]
    }
  },
  {
    /** 聽力 */
    path: '/listening',
    name: 'listening',
    component: () => import('@/views/ListeningView.vue'),
    meta: {
      middleware: [auth, freeClass]
    }
  },
  {
    /** 克漏字 */
    path: '/listening-test',
    name: 'listeningTest',
    component: () => import('@/views/ListeningTestView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 閱讀 */
    path: '/reading',
    name: 'reading',
    component: () => import('@/views/ReadingView.vue'),
    meta: {
      middleware: [auth, freeClass]
    }
  },
  {
    /** 文章內頁 */
    path: '/article/:id',
    name: 'article',
    component: () => import('@/views/ArticleView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 會話 */
    path: '/speaking',
    name: 'speaking',
    component: () => import('@/views/SpeakingView.vue'),
    meta: {
      middleware: [auth, freeClass]
    }
  },
  {
    /** 寫作 */
    path: '/writing',
    name: 'writing',
    component: () => import('@/views/WritingView.vue'),
    meta: {
      middleware: [auth, freeClass]
    }
  },
  {
    /** AI助教 */
    path: '/aiTutor',
    name: 'aiTutor',
    component: () => import('@/views/AiTutorView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 家庭作業 */
    path: '/homework',
    name: 'homework',
    component: () => import('@/views/HomeworkView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 大課表 */
    path: '/schedule',
    name: 'schedule',
    component: () => import('@/views/ScheduleView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 回顧 */
    path: '/videoLibrary',
    name: 'videoLibrary',
    component: () => import('@/views/VideoLibraryView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 課程頁 */
    path: '/lesson',
    name: 'lesson',
    component: () => import('@/views/LessonView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 收錄 */
    path: '/collection',
    name: 'collection',
    component: () => import('@/views/CollectionView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** toeic */
    path: '/toeic',
    name: 'toeic',
    component: () => import('@/views/toeic/ToeicEntryPage.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** toeicCertificate */
    path: '/toeicCertificate',
    name: 'toeicCertificate',
    component: () => import('@/views/toeic/ToeicCertificate.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** toeicExam */
    path: '/toeicExam',
    name: 'toeicExam',
    component: () => import('@/views/toeic/ToeicExamPage.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** toeicRecord */
    path: '/toeicRecord',
    name: 'toeicRecord',
    component: () => import('@/views/toeic/ToeicRecordPage.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 複習頁面 */
    path: '/review',
    name: 'review',
    component: () => import('@/views/ReviewView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 閱讀紀錄 */
    path: '/readingRecord',
    name: 'readingRecord',
    component: () => import('@/views/ReadingRecord.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 課程紀錄 */
    path: '/courseRecord',
    name: 'courseRecord',
    component: () => import('@/views/CourseRecord.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 每週測驗 */
    path: '/weeklyTest',
    name: 'weeklyTest',
    component: () => import('@/views/WeeklyTest.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** CEFR測驗 */
    path: '/cefrTest',
    name: 'cefrTest',
    component: () => import('@/views/CefrTest.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 每週測驗紀錄 */
    path: '/weeklyTestRecord',
    name: 'weeklyTestRecord',
    component: () => import('@/views/WeeklyTestRecord.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 學習統計 */
    path: '/learningStatistics',
    name: 'learningStatistics',
    component: () => import('@/views/LearningStatistics.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/consulting',
    name: 'consulting',
    component: () => import('@/views/ConsultingView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 日記 */
    path: '/diaryView/:id',
    name: 'DiaryView',
    component: () => import('@/views/DiaryView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 日記 */
    path: '/diaryOpenLink/:openId',
    name: 'DiaryOpenLink',
    component: () => import('@/views/DiaryView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 建立、編輯日記 */
    path: '/diaryEdit/:id?',
    name: 'DiaryEdit',
    component: () => import('@/views/DiaryEdit.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 點數紀錄 */
    path: '/usageRecord',
    name: 'usageRecord',
    component: () => import('@/views/UsageRecord.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 登入註冊頁 */
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth]
    }
  },
  {
    /** 英語中學 登入註冊頁 */
    path: '/loginJunior',
    name: 'loginJunior',
    component: () => import('@/views/LoginJuniorView.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth]
    }
  },
  {
    /** 影片播放 */
    path: '/course-video',
    name: 'courseVideo',
    component: () => import('@/views/CourseVideoView.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth]
    }
  },
  {
    /** 客製化首頁 */
    path: '/custom-home-page',
    name: 'customHomePage',
    component: () => import('@/views/CustomHomeView.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth]
    }
  },
  {
    /** 進階搜尋 */
    path: '/advanced-search',
    name: 'advancedSearch',
    component: () => import('@/views/AdvancedSearchView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** Today */
    path: '/today',
    name: 'today',
    component: () => import('@/views/TodayView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** Thank You Page */
    path: '/thank-you',
    name: 'thankYou',
    component: () => import('@/views/ThankYouView.vue'),
    meta: {
      layout: 'NoLayout'
    }
  },
  {
    /** 訪問路徑不存在時一律回到首頁 */
    path: '/:catchAll(.*)',
    redirect: '/'
  }
];

const scrollBehavior = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  savedPosition: any
) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 };
  }
};

let previousRouteName: string | null = null;
export const createAppRouter = () => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: setupLayouts(routes),
    scrollBehavior
  });

  router.beforeEach((to, from, next) => {
    // 檢查是否存在中間件

    if (!to.meta.middleware) {
      return next(); // 如果沒有中間件，直接允許路由繼續
    }
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = { to, from, next };
    let idx = 0; // 初始化中間件索引

    // 定義執行中間件的函數
    const run = () => {
      if (idx < middleware.length) {
        const mw = middleware[idx]; // 獲取當前中間件
        mw({
          ...context,
          next: () => {
            idx++; // 移動到下一個中間件
            run(); // 繼續執行下一個中間件
          }
        });
      } else {
        next(); // 所有中間件執行完畢，繼續路由導航
      }
    };
    run(); // 開始執行中間件
  });

  router.beforeEach((to, from) => {
    if (to.path !== '/login' && to.path !== '/loginJunior') {
      contractExpired();
    }
  });

  router.beforeEach((to, from) => {
    //手機版sidebar課表開新視窗會跳出滿版sidebar，暫時擋一下
    const appStore = useAppStore();
    const user = useUserStore();
    const loginPage = user.userData.roleType === RoleType['isJunior'] ? '/loginJunior' : '/login';

    if (appStore.isMobile) {
      //從註冊來會是先thankU頁
      appStore.isFull = user.isAfterSignUp ? from.path === '/thank-you' : from.path === loginPage;
    }
  });

  router.beforeEach((to, from) => {
    previousRouteName = typeof from.name === 'string' ? from.name : null;
  });

  return { router, previousRouteName };
};

const contractExpired = () => {
  const user = useUserStore();
  const { currentYear, currentMonth, currentDate } = useCurrentDay();

  const IsExpired = computed(() => {
    return (
      compareDates(
        `${currentYear.value}-${currentMonth.value}-${currentDate.value}`,
        user.userData.endDate,
        'after'
      ) &&
      compareDates(
        `${currentYear.value}-${currentMonth.value}-${currentDate.value}`,
        user.userData.pointDate as string,
        'after'
      )
    );
  });

  if (IsExpired.value) {
    usePopStore().popList.warning = true;
  } else {
    console.log('試用沒過期');
  }
};
